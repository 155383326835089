import React, { Component } from 'react';
import PropTypes from 'prop-types';

// vendor components & lib
import { graphql, StaticQuery } from 'gatsby';

// components
import Header from 'components/common/header';
import Layout from 'components/common/layout';
import ServiceBot from 'components/service-bot';

// styles
import 'stylesheets/faq/index.scss';

// component function
class ServicebotLoginPage extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  handleResponse = response => {
    console.log(response);
  };

  render() {
    return (
      <Layout title="Login">
        <Header className="faq_top-section_header" styleType="dark" showLogo />
        <main>
          <ServiceBot.Login onResponse={this.handleResponse} />
        </main>
      </Layout>
    );
  }
}

/*
function TopSection() {
  return (
    <div className="faq_top-section">
      <Header
        className="faq_top-section_header"
        styleType="semi-transparent"
        showLogo
      />

      <ImageAsBackground image={imageChildFluid} />

      <div className="faq_top-section_content">
        <h1 className="faq_top-section_title">Login</h1>
        <p className="faq_description">How can we help you</p>
      </div>

      <div className="faq_top-section_overlay" />
    </div>
  )
}
*/

export default props => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "faq-page.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <ServicebotLoginPage data={data} {...props} />}
  />
);
